import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'

import designBrief from '../documents/4003--design-brief.pdf'
import userScenarios from '../documents/4003--user-scenarios.pdf'
import competitiveAnalysis from '../documents/4003--competitive-analysis.pdf'
import flowcharts from '../documents/4003--flowcharts.pdf'
import graphicAssets from '../documents/4003--graphic-assets.pdf'

const COLOURS = ['#f00', '#ff0', '#0f0', '#0ff', '#00f', '#f0f']
const GENERIC = ['cursive', 'fantasy', 'monospace', 'serif', 'sans-serif']

const arrayOfProjects = [
	{ name: 'Design Brief', link: designBrief },
	{ name: 'Competitive Analysis', link: competitiveAnalysis },
	{ name: 'User Scenarios', link: userScenarios },
	{ name: 'Flow Charts', link: flowcharts },
	{ name: 'Lo-Fi Prototype', link: 'https://sketch.cloud/s/mOyxV' },
	{ name: 'Image/Graphic Assets', link: graphicAssets },
	{ name: 'High Fidelity Prototypes', link: 'https://sketch.cloud/s/o1Rkx' },
	{
		name: 'Final Product Demonstration',
		link: 'https://www.icloud.com/keynote/02G1hIEug-w2TNZhiMKkj1MUA'
	}
]

const Wrapper = styled.main`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
`

const ElementWrapper = styled.section`
	grid-column: 1;
	grid-row: 1;
	width: 100vw;
	height: 100vh;

	&:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

const letsSpinThisPuppy = keyframes`
0% {
	transform: rotate(0deg);
	color: #f00;
}

0.1%,
20% {
	transform: rotate(72deg);
	color: #ff0;
}

20.1%,
40% {
	transform: rotate(144deg);
	color: #0f0;
}

40.1%,
60% {
	transform: rotate(216deg);
	color: #0ff;
}

60.1%,
80% {
	transform: rotate(288deg);
	color: #00f;
}

80.1%,
100% {
	transform: rotate(360deg);
	color: #f0f;
}
`

const Heading = styled.h1`
	font: 900 5vmin/0.85 cursive;
	animation: ${letsSpinThisPuppy} 3s infinite;
	z-index: 1;
`

const Paragraph = styled.p`
	font-size: x-large;
	font-family: fantasy;
	white-space: nowrap;
	padding: 12px 10px 8px;
	border: 4px solid lightgrey;
	border-top-color: #bbb;
	border-right-color: #999;
	border-left-color: #666;
	border-bottom-color: #333;
	background: grey;

	&:hover {
		z-index: 9999;
		border-top-color: #333;
		border-right-color: #666;
		border-left-color: #999;
		border-bottom-color: #bbb;
		background: grey;
	}
`

export default class PageFor4003 extends Component {
	render() {
		return (
			<Wrapper>
				<ElementWrapper>
					<Heading>Aaron Vince&apos;s YSDN 4003 Site</Heading>
				</ElementWrapper>
				{arrayOfProjects.map(({ name, link }, key) => (
					<ElementWrapper key={key}>
						<Paragraph
							style={{
								position: 'fixed',
								top: `${Math.floor(Math.random() * 90) + 5}%`,
								left: `${Math.floor(Math.random() * 90) + 5}%`,
								transform: `rotate(${Math.floor(Math.random() * 60) - 30}deg)`
							}}
						>
							<a
								href={link}
								style={{
									color: `${COLOURS[Math.floor(Math.random() * 6)]}`,
									fontFamily: `${GENERIC[Math.floor(Math.random() * 5)]}`
								}}
							>
								{name}
							</a>
						</Paragraph>
					</ElementWrapper>
				))}
			</Wrapper>
		)
	}
}
